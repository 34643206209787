import React from "react";
import Layout from "./Layout";
import useProps from "./useProps";

const measurements = [
  { 
    id: 'height', 
    name: '身長', 
    unit: 'cm', 
    displayName: '身長'
  },
  {
    id: 'body-weight',
    name: '体重',
    unit: 'kg',
    displayName: '体重'
  },
  {
    id: 'impedance',
    name: 'インピーダンス', 
    unit: '%', 
    displayName: '体脂肪率'
  },
  {
    id: 'one-leg-closed-with-eyes-closed', 
    name: '閉眼片足立ち', 
    unit: '秒', 
    displayName: '閉眼片足立ち'
  },
  {
    id: '10m-obstacle-walk', 
    name: '10ｍ障害歩行', 
    unit: '秒', 
    displayName: '10ｍ障害歩行'
  },
  {
    id: 'two-step-test', 
    name: '２ステップ・テスト', 
    unit: 'm', 
    displayName: '２ステップ・テスト'
  },
  {
    id: 'foot-grip-force', 
    name: '足把持力', 
    unit: 'kg', 
    displayName: '足把持力'
  },
  {
    id: 'bicycle-ergo', 
    name: '自転車エルゴ', 
    unit: 'ml/kg/分', 
    displayName: '最大酸素摂取量'},
  {
    id: 'balance-walk-test', 
    name: 'バランス・ウォーク・テスト', 
    unit: '歩', 
    displayName: 'バランス・ウォーク・テスト'
  }
]

export default () => <Layout {...useProps(measurements)} />
