import { evaluationFormActions } from '@/store/actions/evaluationForm';
import { EvaluationForm, Measurement } from './reducer';

const initialState: EvaluationForm  = {
  phoneNumber: '',
  isLoading: false,
  error: '',
  measurements: [
    {
      id: 'height', 
      name: '身長', 
      unit: 'cm', 
      displayName: '身長',
      measurementValue: '',
      evaluationValue: ''
    },
    {
      id: 'body-weight', 
      name: '体重', 
      unit: 'kg', 
      displayName: '体重',
      measurementValue: '',
      evaluationValue: ''
    },
    {
      id: 'impedance', 
      name: 'インピーダンス', 
      unit: '%', 
      displayName: '体脂肪率',
      measurementValue: '',
      evaluationValue: ''
    },
    {
      id: 'one-leg-closed-with-eyes-closed', 
      name: '閉眼片足立ち', 
      unit: '秒', 
      displayName: '閉眼片足立ち',
      measurementValue: '',
      evaluationValue: ''
    },
    { 
      id: '10m-obstacle-walk', 
      name: '10ｍ障害歩行', 
      unit: '秒', 
      displayName: '10ｍ障害歩行',
      measurementValue: '',
      evaluationValue: ''
    },
    { 
      id: 'two-step-test', 
      name: '２ステップ・テスト', 
      unit: 'm', 
      displayName: '２ステップ・テスト',
      measurementValue: '',
      evaluationValue: ''
    },
    { 
      id: 'foot-grip-force', 
      name: '足把持力', 
      unit: 'kg', 
      displayName: '足把持力',
      measurementValue: '',
      evaluationValue: ''
    },
    {
      id: 'bicycle-ergo', 
      name: '自転車エルゴ', 
      unit: 'ml/kg/分', 
      displayName: '最大酸素摂取量',
      measurementValue: '',
      evaluationValue: ''
    },
    {
      id: 'balance-walk-test', 
      name: 'バランス・ウォーク・テスト', 
      unit: '歩', 
      displayName: 'バランス・ウォーク・テスト',
      measurementValue: '',
      evaluationValue: ''
    }
  ]
}

const evaluationForm = (state = initialState, action: any): EvaluationForm => {
  switch (action.type) {
    case evaluationFormActions.CHANGE_MEASUREMENT_VALUE:
      return { 
        ...state, 
        measurements: state.measurements.map((measurement: Measurement) => {
          if (measurement.id === action.payload.id) {
            return { ...measurement, measurementValue: action.payload.value }
          }
          return measurement
        }) 
      }
    case evaluationFormActions.CHANGE_EVALUATION_VALUE:
      return {
        ...state, 
        measurements: state.measurements.map((measurement: Measurement) => {
          if (measurement.id === action.payload.id) {
            return { ...measurement, evaluationValue: action.payload.value }
          }
          return measurement
        }) 
      }
    case evaluationFormActions.CHANGE_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload.value }
    case evaluationFormActions.REQUEST_CREATE_EVALUATION:
      return { ...state, isLoading: true }
    case evaluationFormActions.RECEIVED_ERROR:
      return { ...state, isLoading: false, error: action.payload.error }
    case evaluationFormActions.RECEIVED_SUCCESS:
      return initialState 
    default:
      return state
  }
}

export { evaluationForm }
