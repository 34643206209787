import { evaluationListActions } from '@/store/actions/evaluationList';
import { EvaluationList } from './reducer';

const initialState: EvaluationList = {
  needLoading: true,
  isLoading: false,
  message: '',
  evaluations: []
}

const evaluationList = (state = initialState, action: any): EvaluationList => {
  switch (action.type) {
    case evaluationListActions.REQUEST_LIST:
      return { ...state, needLoading: false, isLoading: true }
    case evaluationListActions.RECEIVED_ERROR:
      return {
        ...state, 
        needLoading: false, 
        isLoading: false, 
        message: action.payload.error 
      }
    case evaluationListActions.RECEIVED_SUCCESS:
      return { 
        ...state, 
        needLoading: false, 
        isLoading: false,
        evaluations: action.payload.evaluations
      }
    case evaluationListActions.REQUEST_RELOAD:
      return { ...state, needLoading: true }
    default:
      return state
  }
}

export { evaluationList }

