import React from "react";
import Styled from "styled-components";

export type UserFormProp = {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  message: string | null,
  emailAddress: string
  onChangeEmailAddress: (v: string) => void,
  password: string,
  onChangePassword: (v: string) => void,
  isAdmin: boolean,
  onChangeIsAdmin: (c: boolean) => void,
  loading: boolean
};

export default ({
  onSubmit,
  message,
  emailAddress,
  onChangeEmailAddress,
  password,
  onChangePassword,
  isAdmin,
  onChangeIsAdmin,
  loading
}: UserFormProp) => (
  <Form onSubmit={onSubmit}>
    {message && <Message>{message}</Message>}
    <Inputs>
      <Input
        type="email"
        value={emailAddress}
        placeholder="Email Address"
        onChange={({ target: { value } }) => onChangeEmailAddress(value)}
        autoFocus
        required
      />
      <Input
        type="password"
        value={password}
        placeholder="Password"
        onChange={({ target: { value } }) => onChangePassword(value)}
        required
      />
      <Checkbox>
        <label>Is admin?</label>
        <Input
          type="checkbox"
          name="isAdmin"
          checked={isAdmin}
          onChange={({ target: { checked } }) => onChangeIsAdmin(checked)}
        />
    </Checkbox>  
    </Inputs>
    <SubmitButton type="submit" disabled={loading}>
      {loading ? "..." : "Submit"}
    </SubmitButton>
  </Form>
);

const Form = Styled.form`
  box-sizing: border-box;
  width: 95%;
  max-width: 420px;
  margin-top: -30px;
`;

const Message = Styled.div`
  text-align: center;
  color: #d1543f;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

const Inputs = Styled.div`
  margin-bottom: 2rem;
`;

const Input = Styled.input`
  box-sizing: border-box;
  padding: 1.2rem 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  display: block;
  width: 100%;
  outline: none;
  border: 1px solid #eee;
  border-radius: 3px;
  transition: border 0.25s ease, box-shadow 0.25s ease;

  &:focus {
    border-color: rgb(0, 0, 0);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08) inset;
  }
`;

const SubmitButton = Styled.button`
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
  display: block;
  width: 100%;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  outline: none;
  transition: background 0.25s ease;
  background: rgb(0, 0, 0);

  &:hover {
    background: rgb(30, 30, 30);
  }

  &[disabled] {
    background: rgba(80, 80, 80);
  }
`;

const Checkbox = Styled.div`
`
