import React, { useState } from 'react';
import { connect } from 'react-redux';
import Styled from "styled-components";
import { RoundedBorderButton } from "@/components/Button";
import Loading from '@/components/Loading';
import { Measurement, EvaluatedUser, RootReducer } from '@/store/reducers/reducer'

type EvaluationListProps = {
  loading: boolean,
  message: string,
  evaluations: EvaluatedUser[]
}

type UserMeasurementsProps = {
  measurements: Measurement[]
}

const localeString = (date: string, locale = 'ja-JP') => new Date(date).toLocaleString(locale)

const UserMeasurements = ({ measurements }: UserMeasurementsProps) => (
  <Table>
    <THeader>
      <DisplayName>測定項目</DisplayName>
      <Th>測定</Th>
      <Th>評価</Th>
    </THeader>
    {measurements.map((measurement, i) => (
      <Tr key={i}>
        <DisplayName>{measurement.displayName}</DisplayName>
        <Th>{measurement.measurementValue} {measurement.unit}</Th>
        <Th>{measurement.evaluationValue}</Th>
      </Tr>
    ))
    }
  </Table>
)

const Row = ({ phoneNumber, createdAt, measurements }: EvaluatedUser) => {
  const [isHidden, setIsHidden] = useState<boolean>(true)
  return (
    <User>
      <UserRow>
        <PhoneNumber>{phoneNumber}</PhoneNumber>
        <CreatedAt>({localeString(createdAt)})</CreatedAt>
        <Button onClick={() => setIsHidden(!isHidden)}>
          {isHidden ? "詳細" : "隠す"}
        </Button>
      </UserRow>

      {!isHidden && <UserMeasurements measurements={measurements} />
      }
    </User>
  )
}

const EList = ({ loading, message, evaluations }: EvaluationListProps) => (
  <List>
    {message && <Message>{message}</Message>}
    <Loading show={loading} />
    {evaluations.map(
      (evaluation, i) => <Row key={i} phoneNumber={evaluation.phoneNumber} createdAt={evaluation.createdAt} measurements={evaluation.measurements} />)
    }
  </List>
)

const mapStateToProps = (state: RootReducer) => ({
  loading: state.evaluationList.isLoading,
  message: state.evaluationList.message,
  evaluations: state.evaluationList.evaluations
})

const List = Styled.div`
  margin: 1.5rem;
`

const User = Styled.div`
  margin: 0.5rem;
`

const UserRow = Styled.div`
  display: flex;
  width: 80%;
  margin: 0.5rem;
`

const PhoneNumber = Styled.div`
  flex: 1 1 auto;
  font-weight: bold;
`

const CreatedAt = Styled.div`
  flex: 1 1 auto;
`

const Button = Styled(RoundedBorderButton)`
  flex: 1 1 auto;
`

const Table = Styled.div`
  display: flex;
  flex-direction: column;
`

const THeader = Styled.div`
  display: flex;
  font-weight: bold;
`

const Tr = Styled.div`
  display: flex;
`

const DisplayName = Styled.div`
  flex: 1 1 40%;
`

const Th = Styled.div`
  flex: 1 1 30%;
`

const Message = Styled.div`
  text-align: center;
  color: #d1543f;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

export default connect(mapStateToProps)(EList)
