import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunkMiddleware from 'redux-thunk'
import rootReducer from "./reducers";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "@healthcare-console-storage",
  storage,
  whitelist: ['idToken', 'loginUser']
};

export const configureStore = () => {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store = createStore(
    persistedReducer,
    applyMiddleware(thunkMiddleware)
  );
  const persistor = persistStore(store);

  return { store, persistor };
};
