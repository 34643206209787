import { userListActions } from '@/store/actions/userList';
import { ConsoleUser, UserList } from './reducer'

const initialState: UserList = {
  needLoading: true,
  isLoading: false,
  message: '',
  users: []
}

const userList = (state = initialState, action: any): UserList => {
  switch (action.type) {
    case userListActions.REQUEST_USER_LIST:
      return { ...state, needLoading: false, isLoading: true }
    case userListActions.RECEIVED_ERROR:
      return {
        ...state, 
        needLoading: false, 
        isLoading: false, 
        message: action.payload.error 
      }
    case userListActions.RECEIVED_SUCCESS:
      return { 
        ...state, 
        needLoading: false, 
        isLoading: false,
        users: action.payload.users.filter((user: ConsoleUser) => user.email)
      }
    case userListActions.REQUEST_RELOAD:
      return { ...state, needLoading: true }
    default:
      return state
  }
}

export { userList }
