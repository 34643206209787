import React from "react";
import Styled from "styled-components";
import { connect } from "react-redux";
import { WithAuth as Root } from "@/components/Root";
import PageTitle from "@/components/PageTitle";
import { RoundedBorderButton } from "@/components/Button";
import { LayoutProps } from "./props";
import MeasurementRow from '@/components/evaluations/Measurement'
import { mapStateToProps, mapDispatchToProps } from './useProps';
import EvaluationList from '@/components/evaluations/List'

const pageTitle = '身体測定結果'

const Row = connect(mapStateToProps, mapDispatchToProps)(MeasurementRow)

export default ({
  activeForm,
  onCreateEvaluation,
  phoneNumber,
  onChangePhoneNumber,
  onSubmit,
  errorMessage,
  loading,
  measurements
}: LayoutProps) => (
    <Root title={pageTitle}>
      <Header>
        <PageTitle>{pageTitle}</PageTitle>
      </Header>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      {activeForm ?

        <Form onSubmit={onSubmit}>
          <input
            type="text"
            value={phoneNumber}
            placeholder="電話番号"
            onChange={({ target: { value } }) => onChangePhoneNumber(value)}
            required
          />

          <Measurements>
            {measurements.map(measurement => <Row key={measurement.id} {...measurement} />)}
          </Measurements>
          <SubmitButton type="submit" disabled={loading}>
            {loading ? "..." : "Submit"}
          </SubmitButton>
        </Form>
        : <Button onClick={onCreateEvaluation}>身体測定結果を作成</Button>
      }

      <h2>身体測定結果一覧</h2>
      <EvaluationList />

    </Root>
  );

const Header = Styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Form = Styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 95%;
`;

const Measurements = Styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
`

const ErrorMessage = Styled.div`
  text-align: center;
  color: #d1543f;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;

const SubmitButton = Styled.button`
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1rem;
  display: block;
  width: 100%;
  color: #fff;
  border: none;
  cursor: pointer;
  font-weight: bolder;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.35);
  border-radius: 3px;
  outline: none;
  transition: background 0.25s ease;
  background: rgb(0, 0, 0);

  &:hover {
    background: rgb(30, 30, 30);
  }

  &[disabled] {
    background: rgba(80, 80, 80);
  }
`;

const Button = Styled(RoundedBorderButton)`
  margin: 0 0 2rem auto;
`;
