import React from "react";
import Styled from "styled-components";
import { Redirect } from "react-router-dom";
import { WithAuth as Root } from "@/components/Root";
import PageTitle from "@/components/PageTitle";

const pageTitle = 'ホーム'

export default () => (
  <Root title={pageTitle}>
    <Header>
      <PageTitle>{pageTitle}</PageTitle>
    </Header>
    <Redirect to="/evaluations" />
  </Root>
);

const Header = Styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;
