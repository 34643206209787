import { combineReducers } from "redux";
import idToken from "./idToken";
import loginUser from "./loginUser";
import { evaluationForm } from '@/store/reducers/evaluationForm';
import { userForm } from '@/store/reducers/userForm';
import { userList } from '@/store/reducers/userList';
import { evaluationList } from '@/store/reducers/evaluationList';

export default combineReducers({
  idToken,
  loginUser,
  evaluationForm,
  userForm,
  userList,
  evaluationList
});
