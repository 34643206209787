export type ApiResult<T = any> = ApiSuccessResult<T> | ApiFailureResult;

type ApiSuccessResult<T> = {
  type: "Success";
  response: T;
  rawResponse: Response;
};

type ApiFailureResult = {
  type: "Failure";
  rawResponse: Response;
};

type RequestOptions = {
  idToken: string;
  params?: { [key: string]: any };
};

const postRequest = async (endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(
    makeUrl(endPoint), 
    { 
      method: 'POST', 
      headers: makeHeaders(idToken),
      body: JSON.stringify(payload)
    }
  )
  if (!response.ok) {
    throw new Error(response.statusText)
  } 
  return response.json()
}

const getRequest = async (endPoint: string, idToken: string) => {
  const response = await fetch(
    makeUrl(endPoint),
    {
      method: 'GET',
      headers: makeHeaders(idToken)
    }
  )

  if (!response.ok) {
    throw new Error(response.statusText)
  } 
  console.log(response.status)
  return response.json()
}

const verifyReceivedData = (response: any) => {
  if (!response) {
    throw new Error(`Corrupted response ${response}`)
  }

  return response
}

export const postEvaluation = async (idToken:string, payload: any) => {
  return await postRequest('api/evaluations', idToken, payload)
    .then((response) => response)
}

export const postUser = async (idToken: string, payload: any) => {
  return await postRequest('api/users', idToken, payload)
    .then((response) => response)
}

export const getUsers = async (idToken: string) => {
  return await getRequest('api/users', idToken)
    .then((response) => verifyReceivedData(response))
}

export const getEvaluatedUsers = async (idToken: string) => {
  return await getRequest('api/evaluations', idToken)
    .then((response) => verifyReceivedData(response))
}

const baseUrl = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`;
const makeUrl = (endpoint: string) => {
  return `${baseUrl}/${endpoint}`;
};

const makeHeaders = (idToken: string) => ({
  "Content-Type": "application/json",
  Authorization: `Bearer ${idToken}`
});
