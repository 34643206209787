import React from "react";
import { connect } from 'react-redux';
import Styled from "styled-components";
import Loading from '@/components/Loading';
import { RootReducer } from '@/store/reducers/reducer'

interface UserProps {
  email: string;
  isAdmin: boolean;
}

type UserListProps = {
  loading: boolean,
  message: string,
  users: UserProps[]
}

const UserRow = ({ email, isAdmin }: UserProps) => (
  <User>
    <Email>{email}</Email>
    {isAdmin ?
      <Admin>Admin</Admin>
      : <Admin>-</Admin>}
  </User>
)

const UserList = ({ loading, message, users }: UserListProps) => (
  <List>
    {message && <Message>{message}</Message>}
    <Loading show={loading} />
    <Header>
      <Email>メールアドレス</Email>
      <Admin>権限</Admin>
    </Header>
    {users.map((user, i) => <UserRow key={i} email={user.email} isAdmin={user.isAdmin} />)}
  </List>
)

const mapStateToProps = (state: RootReducer) => ({
  loading: state.userList.isLoading,
  message: state.userList.message,
  users: state.userList.users
})

const List = Styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
`

const Header = Styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
`

const User = Styled.div`
  display: flex;
  flex: 1 1 auto;
  margin: 0.5rem;
`

const Email = Styled.div`
  flex: 1 1 70%;
`

const Admin = Styled.div`
  flex: 1 1 30%;
`

const Message = Styled.div`
  text-align: center;
  color: #d1543f;
  font-size: 0.9rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
`;


export default connect(mapStateToProps)(UserList)
