import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { MeasurementItem, LayoutProps } from "./props";
import { 
  changePhoneNumber,
  changeMeasurementValue, 
  changeEvaluationValue,
  submitEvaluation
} from "@/store/actions/evaluationForm";
import { fetchEvaluationList } from '@/store/actions/evaluationList';
import { Measurement, RootReducer } from '@/store/reducers/reducer';

const mapStateToProps = (state: RootReducer, ownProps: MeasurementItem) => {
  const [first] = state.evaluationForm.measurements.filter((m: Measurement) => m.id === ownProps.id)
  return {
    inputValue: first.measurementValue,
    selectValue: first.evaluationValue
  }
}

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
  onChangeInput: (value: string) => dispatch(changeMeasurementValue(ownProps.id, value)),
  onChangeSelect: (value: string) => dispatch(changeEvaluationValue(ownProps.id, value))
})

export { mapStateToProps, mapDispatchToProps }

export default (measurements: MeasurementItem[]): LayoutProps => {
  const dispatch = useDispatch()
  const evaluationsNeedLoading = useSelector((state: RootReducer) => state.evaluationList.needLoading)
  const idToken = useSelector<RootReducer, string | null>(
    ({ idToken }) => idToken
  );
  const phoneNumber = useSelector((state: RootReducer) => state.evaluationForm.phoneNumber)
  const onChangePhoneNumber = (value: string) => dispatch(changePhoneNumber(value))
  const errorMessage = useSelector((state: RootReducer) => state.evaluationForm.error)
  const loading = useSelector((state: RootReducer) => state.evaluationForm.isLoading)
  const [activeForm, setActiveForm] = useState<boolean>(false);

  const onCreateEvaluation = () => setActiveForm(true)
  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(submitEvaluation(idToken ? idToken : ''))

    setActiveForm(false)
  }

  if (evaluationsNeedLoading) 
    dispatch(fetchEvaluationList())

  return {
    activeForm,
    onCreateEvaluation,
    phoneNumber,
    onChangePhoneNumber,
    loading,
    errorMessage,
    onSubmit,
    measurements
  }
}
