import React from "react";
import { connect } from 'react-redux';
import Styled from "styled-components";
import { WithAuth as Root } from "@/components/Root";
import PageTitle from "@/components/PageTitle";
import { RoundedBorderButton } from "@/components/Button";
import { LayoutProps } from "./props";
import UserForm from '@/components/Users';
import UserList from '@/components/Users/List';
import { mapStateToProps, mapDispatchToProps } from './useProps';

const pageTitle = '管理ユーザー'

const Form = connect(mapStateToProps, mapDispatchToProps)(UserForm)

export default ({ formIsActive, onCreateUser, ...props }: LayoutProps) => (
  <Root title={pageTitle}>
    <Header>
      <PageTitle>{pageTitle}</PageTitle>
    </Header>

    {formIsActive ?
      <Form {...props} />
      :
      <Button onClick={onCreateUser}>管理ユーザーを追加</Button>
    }

    <h2>管理ユーザー一覧</h2>
    <UserList />

  </Root>
);

const Header = Styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Button = Styled(RoundedBorderButton)`
  margin: 0 0 2rem auto;
`;
