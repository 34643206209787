import { getUsers } from '@/lib/request';

const userListActions = {
  REQUEST_USER_LIST: 'REQUEST_USER_LIST',
  RECEIVED_ERROR: 'RECEIVED_USER_LIST_ERROR',
  RECEIVED_SUCCESS: 'RECEIVED_USER_LIST_SUCCESS',
  REQUEST_RELOAD: 'REQUEST_USER_LIST_RELOAD'
}

const requestUserList = () => ({
  type: userListActions.REQUEST_USER_LIST
})

const receivedError = (error: string) => ({
  type: userListActions.RECEIVED_ERROR,
  payload: { error }
})

const receivedSuccess = (users: any[]) => ({
  type: userListActions.RECEIVED_SUCCESS,
  payload: { users: (users ? users : []) }
})

const requestReload = () => ({
  type: userListActions.REQUEST_RELOAD
})

const fetchUserList = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    dispatch(requestUserList())

    return getUsers(idToken)
      .then(
        (response: any) => {
          dispatch(receivedSuccess(response.users))
        },
        (error: any) => {
          console.log(error)
          dispatch(receivedError(error.message))
        }
      )
  }
}

export { userListActions, fetchUserList, requestReload };
