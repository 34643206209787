import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LayoutProps } from "./props";
import { changeEmail, toogleIsAdmin, submitUser } from '@/store/actions/userForm';
import { fetchUserList } from '@/store/actions/userList';
import { RootReducer } from '@/store/reducers/reducer'; 

const mapStateToProps = (state: RootReducer) => ({
  message: state.userForm.message,
  emailAddress: state.userForm.email,
  isAdmin: state.userForm.isAdmin,
  loading: state.userForm.isLoading
})

const mapDispatchToProps = (dispatch: any) => ({
  onChangeEmailAddress: (value: string) => dispatch(changeEmail(value)),
  onChangeIsAdmin: (value: boolean) => dispatch(toogleIsAdmin())
})

export default (): LayoutProps => {
  const dispatch = useDispatch()
  const usersNeedLoading = useSelector((state: RootReducer) => state.userList.needLoading)
  const [password, onChangePassword] = useState<string>("");
  const [formIsActive, setFormIsActive] = useState<boolean>(false);

  const onCreateUser = () => setFormIsActive(true)

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(submitUser(password))
    onChangePassword('')
    setFormIsActive(false)
  }

  if (usersNeedLoading) 
    dispatch(fetchUserList())
  
  return {
    formIsActive,
    onCreateUser,
    password,
    onChangePassword,
    onSubmit
  }
}

export { mapStateToProps, mapDispatchToProps }
