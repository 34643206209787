import React from "react";

const LogoInvert = () => (
  <img src="logo_invert.png" height="100" alt="" />
)

const Logo = () => (
  <img src="logo.png" width="400" alt="" />
)

export { Logo, LogoInvert }
