import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import NotFound from "@/pages/not_found";
import { useAuth } from "@/lib/firebase";
import { RootReducer } from '@/store/reducers/reducer'

export const createRouter = (routes: CustomRoute[]) => () => {
  useAuth();

  const idToken = useSelector<RootReducer, string | null>(
    ({ idToken }) => idToken
  );

  const redirectIfNotAuth = (needAuth: boolean, component: JSX.Element) => {
    if (!needAuth) return component;
    return idToken ? component : <Redirect to="/login" />;
  };

  return (
    <BrowserRouter>
      <Switch>
        {routes.map(({ needAuth, path, component: Component }) => (
          <Route exact key={path} path={path}>
            {redirectIfNotAuth(needAuth, <Component />)}
          </Route>
        ))}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
