import { userFormActions } from '@/store/actions/userForm';
import { UserForm } from './reducer'

const initialState: UserForm = {
  isLoading: false,
  message: '',
  email: '',
  isAdmin: false
}

const userForm = (state = initialState, action: any): UserForm => {
  switch (action.type) {
    case userFormActions.CHANGE_EMAIL: 
      return {...state, email: action.payload.value}
    case userFormActions.TOOGLE_ISADMIN: 
      return {...state, isAdmin: !state.isAdmin}
    case userFormActions.REQUEST_CREATE_USER: 
      return {...state, isLoading: true}
    case userFormActions.RECEIVED_ERROR: 
      return {...state, isLoading: false, message: action.payload.error}
    case userFormActions.RECEIVED_SUCCESS: 
      return {...initialState, message: action.payload.response}
    default:
      return state
  }
}

export { userForm }
