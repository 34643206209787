import React from "react";
import Styled from "styled-components";

export type MeasurementProp = {
  key: string,
  name: string,
  unit: string,
  displayName: string,
  inputValue: string,
  onChangeInput: (val: string) => void,
  selectValue: string,
  onChangeSelect: (val: string) => void,
};

export default ({
  key,
  name,
  unit, 
  displayName, 
  inputValue,
  onChangeInput,
  selectValue,
  onChangeSelect
}: MeasurementProp) => (
  <Row key={key}>
    <Name>{name}</Name>
    <Input
      type="text"
      value={inputValue}
      placeholder={displayName}
      onChange={({ target: {value} }) => onChangeInput(value) }
      required
    />
    <Unit>{unit}</Unit>    
  <Select 
    value={selectValue} 
    onChange={({ target: {value} }) => onChangeSelect(value) } 
    name="evaluation">
      <option value="">-</option>
      <option value="A">A</option>
      <option value="B">B</option>
      <option value="C">C</option>
      <option value="D">D</option>
      <option value="E">E</option>
    </Select> 
  </Row>
);

const Row = Styled.div`
  display: flex;
  align-items: stretch;
  margin: 0.5rem;
`;

const Name = Styled.p`
  flex: 1 1 30%;
  font-size: 0.9rem;
`; 

const Input = Styled.input`
  flex: 1 1 40%;
  font-size: 0.9rem;
`;

const Unit = Styled.p`
  flex: 1 1 15%;
  font-size: 0.9rem;
`; 

const Select = Styled.select`
  flex: 1 1 15%;
  font-size: 0.9rem;
`;
