import { postEvaluation } from '@/lib/request';
import { addCountryCode } from '@/store/data/evaluation'

const evaluationFormActions = {
  CHANGE_MEASUREMENT_VALUE: 'CHANGE_MEASUREMENT_VALUE',
  CHANGE_EVALUATION_VALUE: 'CHANGE_EVALUATION_VALUE',
  CHANGE_PHONE_NUMBER: 'CHANGE_PHONE_NUMBER',
  REQUEST_CREATE_EVALUATION: 'REQUEST_CREATE_EVALUATION',
  RECEIVED_ERROR: 'RECEIVED_CREATE_EVALUATION_ERROR',
  RECEIVED_SUCCESS: 'RECEIVED_CREATE_EVALUATION_SUCCESS'
}

// Sync. Actions creators
const changeMeasurementValue = (id: string, value: string) => ({
  type: evaluationFormActions.CHANGE_MEASUREMENT_VALUE,
  payload: {id, value}
})

const changeEvaluationValue = (id: string, value: string) => ({
  type: evaluationFormActions.CHANGE_EVALUATION_VALUE,
  payload: {id, value}
})

const changePhoneNumber = (value: string) => ({
  type: evaluationFormActions.CHANGE_PHONE_NUMBER,
  payload: {value}
})

const requestCreateEvaluation = () => ({
  type: evaluationFormActions.REQUEST_CREATE_EVALUATION
})

const receivedError = (error: string) => ({
  type: evaluationFormActions.RECEIVED_ERROR,
  payload: {error}
})

const receivedSuccess = (response: any) => ({
  type: evaluationFormActions.RECEIVED_SUCCESS,
  payload: {response}
})

// Async. Action creators
const submitEvaluation = (idToken: string) => {
  return (dispatch: any, getState: any) => {
    const evaluation = getState().evaluationForm

    dispatch(requestCreateEvaluation())

    return postEvaluation(idToken, addCountryCode('+81', evaluation))
      .then(
        (response: any) => dispatch(receivedSuccess(response)),
        (error: any) => {
          console.log(error)
          dispatch(receivedError(error.message))
        }
      )
  }
};

export { 
  evaluationFormActions,
  changeMeasurementValue,
  changeEvaluationValue,
  changePhoneNumber,
  submitEvaluation
}
