type Measurement = {
  id: string, 
  name: string, 
  unit: string, 
  displayName: string,
  measurementValue: string, // number
  evaluationValue: string   // A | B ...

}

type Evaluation = {
  phoneNumber: string,
  isLoading: boolean,
  error: string,  // message
  measurements: Measurement[]
}

type EvaluatedUser = {
  phoneNumber: string,
  createdAt: Date
}

type EvaluationList = {
  needLoading: boolean,
  isLoading: boolean,
  message: string,
  evaluations: EvaluatedUser[]
}

const addCountryCode = (countryCode: string, evaluation: Evaluation): Evaluation => {
  if (evaluation.phoneNumber.startsWith('0')) {
    return { 
      ...evaluation, 
      phoneNumber: '+81' + evaluation.phoneNumber.substring(1)
    }
  }

  return evaluation
}

const removeCountryCodeFromUser  = (countryCode: string, evaluatedUser: EvaluatedUser): EvaluatedUser => {
  if (evaluatedUser.phoneNumber.startsWith(countryCode)) {
    return { 
      ...evaluatedUser, 
      phoneNumber: evaluatedUser.phoneNumber.replace(countryCode, '0')
    }
  }

  return evaluatedUser
}

const removeCountryCode = (countryCode: string, evaluatedUsers: EvaluatedUser[]): EvaluatedUser[] => {
  return evaluatedUsers.map((user) => removeCountryCodeFromUser(countryCode, user))
}

export { 
  addCountryCode,
  removeCountryCode
}
