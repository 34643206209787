import { postUser } from '@/lib/request';

const userFormActions = {
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  TOOGLE_ISADMIN: 'TOOGLE_ISADMIN',
  REQUEST_CREATE_USER: 'REQUEST_CREATE_USER',
  RECEIVED_ERROR: 'RECEIVED_CREATE_USER_ERROR',
  RECEIVED_SUCCESS: 'RECEIVED_CREATE_USER_SUCCESS'
}

// Sync. Action creators
const changeEmail = (value: string) => ({
  type: userFormActions.CHANGE_EMAIL,
  payload: { value }
});

const toogleIsAdmin = () => ({
  type: userFormActions.TOOGLE_ISADMIN
})

const requestCreateUser = () => ({
  type: userFormActions.REQUEST_CREATE_USER
})

const receivedError = (error: string) => ({
  type: userFormActions.RECEIVED_ERROR,
  payload: { error }
})

const receivedSuccess = (response: string) => ({
  type: userFormActions.RECEIVED_SUCCESS,
  payload: { response }
})

// Async Action creator
const submitUser = (password: string) => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken
    const newUser = getState().userForm
    dispatch(requestCreateUser())

    return postUser(idToken, { ...newUser, password})
      .then(
        (response: any) => {
          console.log(response)
          dispatch(receivedSuccess('User successfully create'))
        },
        (error: any) => {
          console.log(error)
          dispatch(receivedError(error.message))
        }
      )
  }
}

export { 
  userFormActions,
  changeEmail,
  toogleIsAdmin,
  submitUser
}
