import { useDispatch } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import * as Result from "@/lib/result";
import ActionCreator from "@/store/actions/action_creator";

export default firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
});

export const auth = firebase.auth();

export const login = async (emailAddress: string, password: string) => {
  await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

  const result = await auth
    .signInWithEmailAndPassword(emailAddress, password)
    .then(Result.wrapSuccess)
    .catch(Result.wrapFailure);

  if (result.type === "Failure") {
    return result;
  }

  const userCredential = result.source as firebase.auth.UserCredential;
  const user = userCredential.user;
  const idToken = await user?.getIdToken();

  if (idToken) {
    return result;
  }

  await logout();

  return result;
};

export const logout = () => {
  return auth.signOut();
};

export const useAuth = () => {
  const dispatch = useDispatch();

  firebase.auth().onAuthStateChanged(async user => {
    if (!user) return dispatch(ActionCreator.logout());

    const idToken = await user.getIdToken();

    dispatch(ActionCreator.login({ idToken: idToken, firebaseUser: user }));
  });
};
