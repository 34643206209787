import { createRouter } from "./createRouter";
import TopPage from "@/pages/top";
import LoginPage from "@/pages/login";
import EvaluationsPage from "@/pages/evaluations";
import UsersPage from "@/pages/users";

export const Routes: CustomRoute[] = [
  {
    needAuth: false,
    path: "/login",
    component: LoginPage
  },
  {
    needAuth: true,
    path: "/evaluations",
    component: EvaluationsPage
  },
  {
    needAuth: true,
    path: "/users",
    component: UsersPage
  },
  {
    needAuth: true,
    path: "/",
    component: TopPage
  }
];

export const Router = createRouter(Routes);
