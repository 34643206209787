import { getEvaluatedUsers } from '@/lib/request';
import { removeCountryCode } from '@/store/data/evaluation'

const evaluationListActions = {
  REQUEST_LIST: 'REQUEST_EVALUATION_LIST',
  RECEIVED_ERROR: 'RECEIVED_EVALUATION_ERROR',
  RECEIVED_SUCCESS: 'RECEIVED_EVALUATION_SUCCESS',
  REQUEST_RELOAD: 'REQUEST_EVALUATION_RELOAD'
}

const requestList = () => ({
  type: evaluationListActions.REQUEST_LIST
})

const receivedError = (error: string) => ({
  type: evaluationListActions.RECEIVED_ERROR,
  payload: { error }
})

const receivedSuccess = (evaluations: any[]) => ({
  type: evaluationListActions.RECEIVED_SUCCESS,
  payload: { evaluations: (evaluations ? evaluations : []) }
})

const requestReload = () => ({
  type: evaluationListActions.REQUEST_RELOAD
})

const fetchEvaluationList = () => {
  return (dispatch: any, getState: any) => {
    const idToken = getState().idToken

    dispatch(requestList())

    return getEvaluatedUsers(idToken)
      .then(
        (response: any) => {
          const evaluations = removeCountryCode('+81', response)
          dispatch(receivedSuccess(evaluations))
        },
        (error: any) => {
          console.log(error)
          dispatch(receivedError(error.message))
        }
      )
  }
}

export { evaluationListActions, fetchEvaluationList, requestReload };
